import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-listvalue',
  standalone: true,
  imports: [
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
    MatButtonModule,
  ],
  template: `
    <h1 matDialogTitle>{{ data.title }}</h1>
    <mat-dialog-content class="mb-1">
      <p>{{ data.message }}</p>
      <form [formGroup]="itemForm" (ngSubmit)="submit()">
        <mat-form-field class="w-full">
          <mat-label>{{ data.inputText }}</mat-label>
          <mat-select matNativeControl form="value" formControlName="value">
            <mat-option *ngFor="let item of list" [value]="item.value">
              {{ item.visibleValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <div class="flex justify-between mt-3">
        <button
          mat-raised-button
          color="primary"
          (click)="submit()"
          [disabled]="!itemForm.valid">
          {{ 'SERVICES.CONFIRM.ACTIONS.ACCEPT' | translate }}
        </button>
        <span fxFlex></span>
        <button
          mat-stroked-button
          color="primary"
          type="button"
          (click)="dialogRef.close(false)">
          {{ 'SERVICES.CONFIRM.ACTIONS.CANCEL' | translate }}
        </button>
      </div>
    </mat-dialog-content>
  `,
})
export class AppComfirmListValueComponent implements OnInit {
  public itemForm!: FormGroup;
  public list: {
    value: string;
    visibleValue: string;
  }[] = [];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AppComfirmListValueComponent>
  ) {}

  ngOnInit(): void {
    this.buildItemsForm(this.data.initInputValue);
    this.buildItemsList(this.data.list);
  }

  buildItemsForm(value: any) {
    this.itemForm = this.fb.group({
      value: [value, Validators.required],
    });
  }

  buildItemsList(items: any[]) {
    this.list = items;
  }

  submit() {
    this.itemForm.markAllAsTouched();
    if (!this.itemForm.valid) {
      return;
    }
    this.dialogRef.close(this.itemForm.value.value);
  }
}
