import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { AppComfirmListValueComponent } from './app-confirm-listvalue.component';
import { AppComfirmValueComponent } from './app-confirm-value.component';
import { AppComfirmComponent } from './app-confirm.component';

interface confirmData {
  title?: string;
  message?: string;
  inputText?: string;
  initInputValue?: string;
  numeroPedido?: string;
  itemsList?: {
    value: string;
    visibleValue: string;
  }[];
  isRequired?: boolean;
}

@Injectable()
export class AppConfirmService {
  constructor(
    private dialog: MatDialog,
    private readonly translate: TranslateService
  ) {}

  public confirm(data: confirmData = {}): Observable<boolean> {
    data.title =
      data.title ||
      this.translate.instant('SERVICES.CONFIRM.DEFAULT_VALUES.TITLE');
    data.message =
      data.message ||
      this.translate.instant('SERVICES.CONFIRM.DEFAULT_VALUES.MESSAGE');
    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: '380px',
      disableClose: true,
      data: { title: data.title, message: data.message },
    });
    return dialogRef.afterClosed();
  }

  public confirmWithValue(data: confirmData = {}): Observable<string> {
    data.title =
      data.title ||
      this.translate.instant('SERVICES.CONFIRM.DEFAULT_VALUES.TITLE');
    data.message =
      data.message ||
      this.translate.instant('SERVICES.CONFIRM.DEFAULT_VALUES.MESSAGE');
    data.inputText =
      data.inputText ||
      this.translate.instant('SERVICES.CONFIRM.DEFAULT_VALUES.INPUT_TEXT');
    let dialogRef: MatDialogRef<AppComfirmValueComponent>;
    dialogRef = this.dialog.open(AppComfirmValueComponent, {
      width: '380px',
      disableClose: true,
      data: {
        title: data.title,
        message: data.message,
        inputText: data.inputText,
        initInputValue: data.initInputValue,
        numeroPedido: data.initInputValue,
      },
    });
    return dialogRef.afterClosed();
  }
  public confirmWithListValue(data: confirmData = {}): Observable<string> {
    data.title =
      data.title ||
      this.translate.instant('SERVICES.CONFIRM.DEFAULT_VALUES.TITLE');
    data.message =
      data.message ||
      this.translate.instant('SERVICES.CONFIRM.DEFAULT_VALUES.MESSAGE');
    data.inputText =
      data.inputText ||
      this.translate.instant('SERVICES.CONFIRM.DEFAULT_VALUES.INPUT_TEXT');
    data.itemsList = data.itemsList || [];
    data.initInputValue = data.initInputValue || '';
    let dialogRef: MatDialogRef<AppComfirmListValueComponent>;
    dialogRef = this.dialog.open(AppComfirmListValueComponent, {
      width: '380px',
      disableClose: true,
      data: {
        title: data.title,
        message: data.message,
        inputText: data.inputText,
        initInputValue: data.initInputValue,
        numeroPedido: data.initInputValue,
        list: data.itemsList,
        isRequired: data.isRequired,
      },
    });
    return dialogRef.afterClosed();
  }
}
