import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm',
  standalone: true,
  imports: [TranslateModule, MatDialogModule, MatButtonModule],
  template: `<h1 matDialogTitle class="mb-05">{{ data.title }}</h1>
    <mat-dialog-content class="mb-1">
      <p>{{ data.message }}</p>
      <div class="flex justify-between mt-3">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="dialogRef.close(true)">
          {{ 'SERVICES.CONFIRM.ACTIONS.ACCEPT' | translate }}
        </button>

        <button
          type="button"
          color="primary"
          mat-stroked-button
          (click)="dialogRef.close(false)">
          {{ 'SERVICES.CONFIRM.ACTIONS.CANCEL' | translate }}
        </button>
      </div>
    </mat-dialog-content> `,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
