import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-value',
  standalone: true,
  imports: [
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatOptionModule,
    ReactiveFormsModule,
    TranslateModule,
    MatInputModule,
    MatButtonModule,
  ],
  template: `<h1 matDialogTitle class="mb-05">{{ data.title }}</h1>
    <mat-dialog-content class="mb-1">
      <p>{{ data.message }}</p>
      <mat-form-field class="w-full">
        <mat-label>{{ data.inputText }}</mat-label>
        <input matInput [(ngModel)]="data.numeroPedido" />
      </mat-form-field>
      <div class="flex justify-between mt-3">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="dialogRef.close(data.numeroPedido)">
          {{ 'SERVICES.CONFIRM.ACTIONS.ACCEPT' | translate }}
        </button>

        <button
          type="button"
          color="primary"
          mat-stroked-button
          (click)="dialogRef.close(false)">
          {{ 'SERVICES.CONFIRM.ACTIONS.CANCEL' | translate }}
        </button>
      </div>
    </mat-dialog-content>`,
})
export class AppComfirmValueComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmValueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
